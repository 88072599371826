import React from 'react';
import Regions from '@enonic/react-components/Regions';

import './oneColumn.scss';
import { OneColumnPropsType } from './type.d'

function OneColumn(props: Readonly<OneColumnPropsType>) {
  const { regionsData, names, backgroundColor, maxWidth } = props
  const widthClass = maxWidth === 'full' ? 'w-full' : 'l-container mx-auto';

  return (
    <div className={`w-full ${backgroundColor}`}>
      <div className={`${widthClass}`}>
        <Regions regionsData={regionsData} names={names} />
      </div>
    </div>
  );
};

export default (props: Readonly<OneColumnPropsType>) => <OneColumn {...props} />;
